import { replace } from 'connected-react-router'
import React, { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { CreateCruiseItemViewModel } from './models'
import { CreateItemProps, createCruisePayloadFromViewModel } from './utils'
import { formOptions } from '../Common/Fields/config'

import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import { useAppDispatch } from 'hooks/reduxHooks'
import CruiseFields from 'tripPlanner/components/TripModal/TripItems/Common/CruiseFields'
import { useCreateTripItem } from 'tripPlanner/hooks/api'
import useModalCallbacks from 'tripPlanner/hooks/View/useModalCallbacks'
import { TripItem } from 'tripPlanner/types/tripItem'
import { itemSectionId } from 'tripPlanner/utils/itemSection'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'

type ViewModel = CreateCruiseItemViewModel

function CreateCruise(props: CreateItemProps) {
  const { tripId, defaultDate } = props

  const { closeModal, goBack } = useModalCallbacks()

  const methods = useForm<ViewModel>({
    ...formOptions,
    defaultValues: {
      type: 'CRUISE',
      sourceType: 'NA',
      isBooked: false,
      startDate: defaultDate,
      endDate: defaultDate,
      startPlace: {},
      endPlace: {},
    },
  })
  const { handleSubmit } = methods
  const dispatch = useAppDispatch()

  const { mutate: createMutate, isLoading } = useCreateTripItem({
    onSuccess: ({ id }: TripItem) => {
      dispatch(replace({ hash: itemSectionId(id) }))
      closeModal()
    },
  })

  const createTourCruiseItem = useCallback(
    (viewModel: ViewModel) => {
      createMutate({
        tripId,
        tripItem: createCruisePayloadFromViewModel(viewModel),
      })
    },
    [createMutate, tripId],
  )

  return (
    <>
      <ModalHeader
        title="Add cruise"
        onCloseButtonClick={closeModal}
        onBackButtonClick={goBack}
      />
      <FormProvider {...methods}>
        <ModalBody>
          <ModalContent>
            <CruiseFields tripId={tripId} />
          </ModalContent>
        </ModalBody>

        <ModalFooter
          primaryActionProps={{
            onClick: handleSubmit(createTourCruiseItem),
            children: 'Save',
            disabled: isLoading,
          }}
        />
      </FormProvider>

      {isLoading && <LoadingIndicator floating />}
    </>
  )
}

export default CreateCruise
